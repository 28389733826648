<script setup lang="ts">
import { NotificationStore } from '@/store/notification.store'
import { useQueryClient } from '@tanstack/vue-query'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { object, string } from 'zod'
import { useCreateShareLink } from '@/composables/sharing'
import { getShareLink } from '@/utils/helpers/document'

const props = defineProps<{
    itemType: 'document' | 'folder'
    itemId: string
}>()

const [visible, toggleVisible] = useToggle(false)

const { $notificationStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore

const queryClient = useQueryClient()

const allowComment = ref(false)
const allowEdit = ref(false)

const { handleSubmit, useFieldModel, errors } = useForm({
    validationSchema: toTypedSchema(
        object({
            name: string().optional(),
            password: string().refine(
                (val) => val === '' || (val.length >= 8 && val.length <= 12),
                {
                    message: 'Password should be 8 to 12 characters long',
                }
            ),
        })
    ),
    initialValues: {
        name: '',
        password: '',
    },
})
const name = useFieldModel('name')
const password = useFieldModel('password')

const { mutateAsync, isPending } = useCreateShareLink()

const reset = () => {
    password.value = ''
    allowComment.value = false
    allowEdit.value = false
}

const generateLink = handleSubmit(async (values) => {
    const payload = {
        link_name: values.name?.trim() ?? '',
        allow_comment: allowComment.value,
        allow_edit: allowEdit.value,
        [`${props.itemType}_id`]: props.itemId,
    }
    if (values.password && values.password.length > 1) {
        payload.password = values.password
    }
    const response = await mutateAsync(payload)
    // Extract the share ID from the response URL
    const shareId = response.link.split('/').pop()

    if (shareId) {
        const fullUrl = getShareLink(shareId, props.itemId, props.itemType)

        // Copy the URL to clipboard
        try {
            await navigator.clipboard.writeText(fullUrl)
        } catch (err) {
            console.error('Failed to copy: ', err)
            notificationStore.notifyError({
                summary: 'Copy Failed',
                detail: 'Unable to copy the link. Please try again.',
            })
        }
    } else {
        console.error('Failed to extract share ID from the response')
        notificationStore.notifyError({
            summary: 'Link Generation Error',
            detail: 'Unable to generate the share link. Please try again.',
        })
    }

    queryClient.invalidateQueries({
        queryKey: ['documents', props.itemId],
    })
    queryClient.invalidateQueries({
        queryKey: ['folders', props.itemId],
    })

    reset()
    toggleVisible()
    notificationStore.notifySuccess({
        detail: 'Link generated and copied to clipboard',
    })
})
</script>

<template>
    <Button
        severity="success"
        @click.stop="toggleVisible()"
        class="p-2"
        size="small"
        >Generate New Link</Button
    >
    <Dialog
        :visible="visible"
        modal
        header="Generate New Link"
        position="top"
        :draggable="false"
        :closable="true"
        :style="{ width: '40vw' }"
        @update:visible="toggleVisible"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="mt-4">
            <label class="font-bold flex align-items-center text-sm">
                Name
            </label>
            <small
                >Enter the name of the person you are sending this link to.
                (optional)</small
            >
            <InputText
                type="text"
                size="small"
                :class="{ 'p-invalid': errors.name }"
                class="w-full mt-2"
                placeholder="Public"
                v-model="name"
            />
            <small
                v-if="errors.name"
                class="p-error mt-1 text-left block w-full"
            >
                {{ errors.name }}
            </small>
        </div>
        <div class="flex justify-content-between mt-2">
            <div>
                <label
                    for="allowComment"
                    class="font-bold flex align-items-center text-sm"
                >
                    Allow Comment
                </label>
                <small>Allow viewers to add comments to the document.</small>
            </div>

            <InputSwitch
                id="allowComment"
                v-model="allowComment"
                :pt="{
                    slider: {
                        class: 'w-2.5rem',
                    },
                }"
            />
        </div>
        <div class="flex justify-content-between mt-4">
            <div>
                <label
                    for="allowComment"
                    class="font-bold flex align-items-center text-sm"
                >
                    Allow Edit
                </label>
                <small>Allow viewers to edit the document.</small>
            </div>

            <InputSwitch
                id="allowEdit"
                v-model="allowEdit"
                :pt="{
                    slider: {
                        class: 'w-2.5rem',
                    },
                }"
            />
        </div>
        <div class="mt-4">
            <label class="font-bold flex align-items-center text-sm">
                Password
            </label>
            <small>Require a password to view the document.</small>
            <InputText
                type="password"
                size="small"
                :class="{ 'p-invalid': errors.password }"
                class="w-full mt-2"
                placeholder="Password"
                v-model="password"
            />
            <small
                v-if="errors.password"
                class="p-error mt-1 text-left block w-full"
            >
                {{ errors.password }}
            </small>
        </div>
        <div class="mt-4 text-right">
            <Button
                label="Create"
                :disabled="isPending"
                :loading="isPending"
                icon="pi pi-save"
                loading-icon="pi pi-spin pi-spinner"
                @click="generateLink"
                class="p-2"
                size="small"
            ></Button>
        </div>
    </Dialog>
</template>
