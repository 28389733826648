import SharingService from '@/services/sharing.service'
import { useMutation } from '@tanstack/vue-query'

export const useUpdateLink = () => {
    const { $sharingService } = useNuxtApp()
    const sharingService = $sharingService as SharingService

    return useMutation({
        mutationKey: ['update-permissions'],
        mutationFn: sharingService.updatePermissions,
    })
}
