type getBreadcrumbsType = {
    folders: Ref<{
        folders: {
            key: string
            label: string
            parent: string | null
        }[]
    }>
    folderKey: Ref<string>
}
export const getBreadcrumbs = ({ folders, folderKey }: getBreadcrumbsType) => {
    const breadcrumbs = []
    let currentFolder = toValue(folders).folders.find(
        (folder) => folder.key === toValue(folderKey)
    )

    while (currentFolder) {
        breadcrumbs.unshift({
            label: currentFolder.label,
            to: currentFolder.key,
            disabled: currentFolder.key === toValue(folderKey),
        }) // Add label to the beginning of the array
        currentFolder = toValue(folders).folders.find(
            (folder) => folder.key === currentFolder?.parent
        )
    }

    return breadcrumbs
}

export const getFileName = (filename: string) => {
    const name = filename.split('.')[0]
    return name.replace(/[^a-zA-Z0-9\s\-_]/g, '_')
}

export const getShareLink = (
    share_id: string,
    id: string,
    type: string,
    internal: boolean = false
) => {
    const config = useRuntimeConfig()
    const route =
        'shared/' +
        share_id +
        (type.trim() === 'document' ? '/d/' : '/folder/') +
        id

    return internal ? `/${route}` : `${config.public.appUrl}/${route}`
}
