<script setup lang="ts">
import { useUpdatePassword } from '@/composables/sharing'
import { NotificationStore } from '@/store/notification.store'
import { toTypedSchema } from '@vee-validate/zod'
import { useField } from 'vee-validate'
import { string } from 'zod'

const props = defineProps<{
    share_id: string
}>()

const [visible, toggleVisible] = useToggle(false)

const { mutateAsync, isPending } = useUpdatePassword()
const { $notificationStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore

const { value: password, errorMessage: passwordError } = useField<string>(
    'password',
    toTypedSchema(string().min(8))
)

const updatePassword = async () => {
    await mutateAsync({
        id: props.share_id,
        new_password: password.value,
    })
    notificationStore.notifySuccess({
        detail: 'Password updated',
    })
    toggleVisible()
}
</script>

<template>
    <Button @click="toggleVisible(true)" size="small" class="p-2"
        >Update Password</Button
    >
    <Dialog
        :visible="visible"
        modal
        header="Update Password"
        position="top"
        :draggable="false"
        :closable="true"
        :style="{ width: '35vw' }"
        @update:visible="toggleVisible"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <small class="text-left block w-full mb-4">
            This password will be used to access the document. It must be at
            least 8 characters long.
        </small>
        <InputText
            type="password"
            size="small"
            :class="{ 'p-invalid': passwordError }"
            class="w-full"
            placeholder="Password"
            v-model="password"
            aria-describedby="sharingPasswordError"
        />
        <small v-if="passwordError" class="p-error mt-1 text-left block w-full">
            {{ passwordError }}
        </small>
        <div class="mt-4 text-right">
            <Button
                icon="pi pi-save"
                loading-icon="pi pi-spin pi-spinner"
                :loading="isPending"
                size="small"
                class="p-2"
                label="Update"
                @click="updatePassword"
            >
            </Button>
        </div>
    </Dialog>
</template>
