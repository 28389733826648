import DocumentService from '@/services/document.service'
import { useMutation } from '@tanstack/vue-query'
import { NotificationStore } from '@/store/notification.store'

export const useCreateShareLink = () => {
    const { $documentService, $notificationStore } = useNuxtApp()
    const documentService = $documentService as DocumentService
    const notificationStore = $notificationStore as NotificationStore
    return useMutation({
        mutationFn: documentService.documentShareLink,
        onError: () => {
            notificationStore.notifyError({
                summary: 'Error Updating Documents Sharing',
                detail: '',
                life: 3000,
            })
        },
    })
}
