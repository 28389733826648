import DocumentService from '@/services/document.service'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { NotificationStore } from '@/store/notification.store'

export const useShareBulkDocuments = () => {
    const { $documentService, $notificationStore } = useNuxtApp()
    const documentService = $documentService as DocumentService
    const notificationStore = $notificationStore as NotificationStore
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: documentService.shareBulkDocuments,
        onSuccess: () => {
            notificationStore.notifySuccess({
                summary: 'Collaborators updated successfully',
                detail: '',
                life: 3000,
            })
            queryClient.invalidateQueries({
                queryKey: ['documents'],
            })
        },
        onError: () => {
            notificationStore.notifyError({
                summary: 'Error Sharing Selected Documents',
                detail: '',
                life: 3000,
            })
        },
    })
}
