<script setup lang="ts">
import { useUpdateLink } from '@/composables/sharing'
import { ShareId } from '@/services/document.service'
import { NotificationStore } from '@/store/notification.store'
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps<{
    share: ShareId
    itemId: string
}>()

const [visible, toggleVisible] = useToggle(false)
const allowComment = ref(props.share.allow_comment)
const allowEdit = ref(props.share.allow_edit)
const name = ref(props.share.link_name)
const { mutateAsync, isPending } = useUpdateLink()
const queryClient = useQueryClient()
const { $notificationStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore

const updateLink = async () => {
    await mutateAsync({
        share_id: props.share.share_id,
        body: {
            allow_comment: allowComment.value,
            allow_edit: allowEdit.value,
            link_name: name.value,
        },
    })
    queryClient.invalidateQueries({
        queryKey: ['documents', props.itemId],
    })
    notificationStore.notifySuccess({
        detail: 'Link updated successfully.',
    })
    toggleVisible()
}
</script>

<template>
    <Button @click="toggleVisible(true)" size="small" class="p-2"
        >Update Link</Button
    >
    <Dialog
        :visible="visible"
        modal
        header="Update Link"
        position="top"
        :draggable="false"
        :closable="true"
        :style="{ width: '40vw' }"
        @update:visible="toggleVisible"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div>
            <label class="font-bold flex align-items-center text-sm">
                Name
            </label>

            <InputText
                type="text"
                size="small"
                class="w-full mt-2"
                placeholder="Public"
                v-model="name"
            />
        </div>
        <div class="flex justify-content-between mt-4">
            <div>
                <label
                    for="allowComment"
                    class="font-bold flex align-items-center text-base"
                >
                    Allow Comment
                </label>
                <small>Allow viewers to add comments to the document.</small>
            </div>

            <InputSwitch
                id="allowComment"
                v-model="allowComment"
                :pt="{
                    slider: {
                        class: 'w-2.5rem',
                    },
                }"
            />
        </div>
        <div class="flex justify-content-between mt-4">
            <div>
                <label
                    for="allowComment"
                    class="font-bold flex align-items-center text-base"
                >
                    Allow Edit
                </label>
                <small>Allow viewers to edit the document.</small>
            </div>

            <InputSwitch
                id="allowComment"
                v-model="allowEdit"
                :pt="{
                    slider: {
                        class: 'w-2.5rem',
                    },
                }"
            />
        </div>
        <div class="mt-4 text-right">
            <Button
                icon="pi pi-save"
                loading-icon="pi pi-spin pi-spinner"
                :loading="isPending"
                size="small"
                class="p-2"
                label="Update"
                @click="updateLink"
            >
            </Button>
        </div>
    </Dialog>
</template>
