<script setup lang="ts">
import { NotificationStore } from '@/store/notification.store'
import { ShareId } from '@/services/document.service'
import UpdateLinkDialog from './UpdateLinkDialog.vue'
import NewLinkDialog from './NewLinkDialog.vue'
import {
    useDeleteShareLink,
    useToggleDocumentShareLink,
} from '@/composables/sharing'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useQueryClient } from '@tanstack/vue-query'
import { getShareLink } from '@/utils/helpers/document'

const props = defineProps<{
    itemType: 'document' | 'folder'
    itemId: string
    isPublicShare?: boolean
    shareIds: ShareId[]
}>()

const { $notificationStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore
const toggleDocumentShareLinkMutation = useToggleDocumentShareLink()
const { mutateAsync: deleteShareLinkMutation, isPending: isDeleting } =
    useDeleteShareLink()
const queryClient = useQueryClient()

const deletingShareLink = ref<{ share_id: null | string }>({
    share_id: null,
})

const [publicLinkSharingVisible, togglePublicLinkSharing] = useToggle(
    props.isPublicShare
)
const [newLinkButtonVisible, toggleNewLinkButtonVisible] = useToggle(
    props.isPublicShare
)

watch(
    () => props.isPublicShare,
    (newIsPublicShare) => {
        togglePublicLinkSharing(newIsPublicShare)
    }
)

const copyPulicShareLink = (share_id: string) => {
    const newLink = getShareLink(share_id, props.itemId, props.itemType)
    navigator.clipboard.writeText(newLink)
    notificationStore.notifySuccess({
        detail: 'Link copied to clipboard',
    })
}

const toggleShareLink = async () => {
    const res = await toggleDocumentShareLinkMutation.mutateAsync({
        [`${props.itemType}_id`]: props.itemId,
    })
    const isEnabled = res.message.includes('enabled')

    toggleNewLinkButtonVisible(isEnabled)
}

const deleteShareLink = async (share_id: string) => {
    deletingShareLink.value = {
        share_id,
    }
    await deleteShareLinkMutation(share_id)

    deletingShareLink.value = { share_id: null }

    queryClient.invalidateQueries({
        queryKey: ['documents', props.itemId],
    })
    queryClient.invalidateQueries({
        queryKey: ['folders', props.itemId],
    })

    notificationStore.notifySuccess({
        detail: 'Link deleted successfully.',
    })
}
</script>

<template>
    <Accordion
        expandIcon=" "
        collapseIcon=" "
        :select-on-focus="false"
        @tab-click="() => togglePublicLinkSharing()"
        :active-index="isPublicShare ? 0 : 1"
    >
        <AccordionTab
            :pt="{
                headerAction: {
                    class: 'block',
                },
                headerIcon: {
                    class: 'hidden',
                },
            }"
        >
            <template #header>
                <div
                    @click="toggleShareLink()"
                    class="flex align-items-center justify-content-between"
                >
                    <div class="flex align-items-center gap-8">
                        <div class="flex align-items-center gap-3">
                            <i class="pi pi-share-alt text-2xl"></i>
                            <label
                                for="isPublicLinkActive"
                                class="font-semibold text-lg"
                            >
                                Allow public sharing
                                <i
                                    class="pi pi-info-circle ml-2 text-sm"
                                    v-tooltip:right="
                                        'Allow anyone to access this document or folder.'
                                    "
                                >
                                </i>
                            </label>
                        </div>

                        <InputSwitch
                            id="isPublicLinkActive"
                            :pt="{
                                root: {
                                    style: {
                                        height: '25px',
                                        width: '45px',
                                    },
                                },
                            }"
                            :model-value="publicLinkSharingVisible"
                        />
                    </div>
                    <NewLinkDialog
                        v-if="publicLinkSharingVisible && newLinkButtonVisible"
                        :itemType="itemType"
                        :itemId="itemId"
                        class="header-action"
                    />
                </div>
            </template>
            <!-- <div class="flex justify-content-end mb-2">
                <NewLinkDialog :itemType="itemType" :itemId="itemId" />
            </div> -->
            <DataTable size="small" :value="shareIds">
                <Column field="share_id" header="Link">
                    <template #body="{ data }">
                        <a
                            :href="
                                getShareLink(data.share_id, itemId, itemType)
                            "
                            class="inline-block w-10rem"
                            target="_blank"
                        >
                            {{
                                data.link_name
                                    ? data.link_name
                                    : getShareLink(
                                          data.share_id,
                                          itemId,
                                          itemType
                                      )
                                          .substring(0, 20)
                                          .padEnd(23, '.')
                            }}
                        </a>
                        <Button
                            icon="pi pi-copy"
                            size="small"
                            link
                            class="ml-2 vertical-align-baseline"
                            label="Copy"
                            @click="copyPulicShareLink(data.share_id)"
                        />
                    </template>
                </Column>
                <Column field="allow_edit" header="Allow Edit">
                    <template #body="{ data }">
                        <div class="text-center">
                            <i
                                class="pi pi-check text-green-500"
                                v-if="data.allow_edit"
                            ></i>
                            <i class="pi pi-times text-red-500" v-else></i>
                        </div>
                    </template>
                </Column>
                <Column field="allow_comment" header="Allow Comment">
                    <template #body="{ data }">
                        <div class="text-center">
                            <i
                                class="pi pi-check text-green-500"
                                v-if="data.allow_comment"
                            ></i>
                            <i class="pi pi-times text-red-500" v-else></i>
                        </div>
                    </template>
                </Column>
                <Column header="">
                    <template #body="{ data }">
                        <div
                            class="flex align-items-center gap-2 justify-content-end"
                        >
                            <UpdateLinkDialog :share="data" :item-id="itemId" />
                            <PublicSharingUpdatePasswordDialog
                                :share_id="data.share_id"
                            />
                            <Button
                                @click="deleteShareLink(data.share_id)"
                                icon="pi pi-trash"
                                :loading="
                                    isDeleting &&
                                    deletingShareLink.share_id === data.share_id
                                "
                                loading-icon="pi pi-spinner"
                                severity="danger"
                                class="p-0"
                                text
                                rounded
                            ></Button>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </AccordionTab>
    </Accordion>
</template>

<style scoped lang="scss"></style>
