<script setup lang="ts">
import { ref, watch } from 'vue'
import DocumentService from '@/services/document.service'
import { AuthStore } from '@/store/auth.store'
import { useDocument, useShareBulkDocuments } from '@/composables/document'
import ShareLinkPublic from '@/components/PublicSharing/ShareLinkPublic.vue'

const { $documentService, $authStore } = useNuxtApp()
const documentService = $documentService as DocumentService
const authStore = $authStore as AuthStore
const [fetchingCollaborators, toggleFetchingCollaborators] = useToggle(false)

const shareBulkDocumentsMutation = useShareBulkDocuments()

const props = withDefaults(
    defineProps<{
        docIds: Array<string>
        visible: boolean
        multiple?: boolean
        title?: string
    }>(),
    {
        multiple: false,
        title: '',
    }
)

const emit = defineEmits<{
    'update:visible': [value: boolean]
}>()

const shareInput = ref([])
const pendingSharingActions = ref(false)
const filteredUsersList = ref<
    {
        code: string
        name: string
        email: string
        username: string
    }[]
>([])
const collaboratorsList = ref<
    {
        id: string
        username: string
        email: string
        isMe: boolean
        isOwner: boolean
    }[]
>([])

const closeDialog = () => {
    emit('update:visible', false)
    clearShareInput()
}

const clearShareInput = () => {
    shareInput.value = []
}

const searchUser = async (evt: any) => {
    setTimeout(async () => {
        if (!evt.query.trim().length) {
            filteredUsersList.value = []
        } else {
            const responseUsers = await documentService.getUsersByUsernameEmail(
                evt.query.trim().toLowerCase()
            )
            const usersList = Object.values(responseUsers) || []
            filteredUsersList.value =
                usersList.length > 0
                    ? usersList.map((x: any) => ({
                          code: x.email,
                          name: `${x.username} (${x.email})`,
                          username: x.username,
                          email: x.email,
                      }))
                    : []
        }
    }, 250)
}

const removeCollaborator = (id: any) => {
    collaboratorsList.value = collaboratorsList.value.reduce(
        (acc: any, item: any) => {
            if (item.id !== id) {
                acc.push(item)
            }
            return acc
        },
        []
    )
    pendingSharingActions.value = true
}

const addCollaborators = async () => {
    const currentCollaborators = collaboratorsList.value
        .filter((item: any) => item.isOwner === false)
        .map((item: any) => item.id)
    const newCollaborators = shareInput.value
        .filter((item: any) => item.code !== authStore?.userData?.email)
        .map((item: any) => item.code)
    const collaborators = [
        ...new Set([...currentCollaborators, ...newCollaborators]),
    ]
    // const response = await documentService.shareDocument(doc._id, collaborators)
    await shareBulkDocumentsMutation.mutateAsync({
        collaborators: collaborators,
        document_ids: props.docIds,
    })
    clearShareInput()
    closeDialog()
}

// const updateCollaboratorsList = async () => {
//         const responseUsers = await documentService.getDocumentUsersByID(
//             docIds
//         )
//         collaboratorsList.value = responseUsers
//             ? Object.values(responseUsers).map((item: any) => ({
//                   id: item.email,
//                   username: item.username,
//                   email: item.email,
//                   isMe:
//                       item.email === authStore?.userData?.email ? true : false,
//                   isOwner:
//                       item.email === authStore?.userData?.email ? true : false,
//               }))
//             : []
//         pendingSharingActions.value = false

// }

const updateCollaboratorsList = async () => {
    const allCollaborators = new Map<string, any>()
    toggleFetchingCollaborators(true)
    const responses = await Promise.all(
        props.docIds.map((docId) => documentService.getDocumentUsersByID(docId))
    )

    responses.forEach((responseUsers) => {
        if (responseUsers) {
            const users = Object.values(responseUsers).map((item: any) => ({
                id: item.email,
                username: item.username,
                email: item.email,
                isMe: item.email === authStore?.userData?.email,
                isOwner: item.email === authStore?.userData?.email,
            }))

            users.forEach((user) => {
                if (!allCollaborators.has(user.id)) {
                    allCollaborators.set(user.id, user)
                }
            })
        }
    })

    toggleFetchingCollaborators(false)
    collaboratorsList.value = Array.from(allCollaborators.values())
    pendingSharingActions.value = false
}

const removeCollaborators = async (id: any) => {
    const currentCollaborators = collaboratorsList.value
        .filter((item: any) => item.isOwner === false)
        .map((item: any) => item.id)
    // const response = await documentService.shareDocument(
    //     doc._id,
    //     currentCollaborators
    // )
    const response = shareBulkDocumentsMutation.mutateAsync({
        collaborators: currentCollaborators,
        document_ids: props.docIds,
    })
    if ((await response) === true) {
        await updateCollaboratorsList()
    }
    pendingSharingActions.value = false
}

const documentData = inject(documentKey)

const enabled = computed(() => {
    return !!documentData?.value.document_id.value && !!props.visible
})

const documentId = ref(documentData?.value.document_id || ref(''),)
const { data: document } = useDocument(documentId)

watch(
    () => props.visible,
    (newVisibleValue) => {
        if (newVisibleValue) {
            updateCollaboratorsList()
        }
    },
    { immediate: true }
)
</script>
<template>
    <Dialog
        :visible="visible"
        :closeOnEscape="false"
        :closable="false"
        modal
        :header="
            multiple ? 'Share Documents' : 'Share &quot;' + title + '&quot;'
        "
        @hide="closeDialog"
        position="top"
        :draggable="false"
        style="width: 60vw"
        :breakpoints="{ '1280px': '80vw', '1024px': '90vw', '575px': '90vw' }"
    >
        <template #header>
            <div
                class="inline-flex align-items-center justify-content-center gap-2"
            >
                <Button
                    v-if="shareInput.length > 0"
                    class="p-dialog-header-icon p-dialog-header-close p-link"
                    text
                    rounded
                    icon="pi pi-arrow-left"
                    aria-label="Go back"
                    type="button"
                    @click="clearShareInput"
                ></Button>
                <span class="p-dialog-title">
                    {{
                        multiple
                            ? 'Share Documents'
                            : 'Share &quot;' + title + '&quot;'
                    }}
                </span>
            </div>
        </template>
        <div class="flex">
            <AutoComplete
                v-if="!pendingSharingActions"
                :dropdown="false"
                :multiple="true"
                v-model="shareInput"
                :suggestions="filteredUsersList"
                @complete="searchUser($event)"
                placeholder="Add users by email/username"
                field="name"
                class="flex-grow-1"
            />
            <span v-if="pendingSharingActions"
                ><i>Pending actions... (Removing collaborators)</i></span
            >
        </div>
        <div class="flex flex-column mt-4">
            <h5>Users with access</h5>
            <div
                v-if="fetchingCollaborators"
                class="flex justify-content-center"
            >
                <spinner :isLoading="true" />
            </div>
            <div
                v-else
                class="flex mb-2"
                v-for="(cll, idx) in collaboratorsList"
                :key="idx"
            >
                <div class="flex flex-grow-1 flex-column">
                    <span class="font-medium">{{
                        cll.username + (cll.isMe ? ' (you)' : '')
                    }}</span>
                    <span class="text-sm">{{ cll.email }}</span>
                </div>
                <div class="flex alignt-items-center justify-content-center">
                    <span class="text-color-secondary">{{
                        cll.isOwner ? 'Owner' : ''
                    }}</span>
                    <Button
                        v-if="!cll.isOwner"
                        icon="pi pi-trash"
                        label=""
                        :v-tooltip="'Remove access'"
                        text
                        severity="danger"
                        @click="removeCollaborator(cll.id)"
                    />
                </div>
            </div>
        </div>

        <ShareLinkPublic
            v-if="document?.Id"
            class="mt-4"
            itemType="document"
            :itemId="document?.Id"
            :isPublicShare="document.isPublicShare"
            :shareIds="document?.shareIds"
        />

        <template #footer>
            <div v-if="shareInput.length > 0" class="flex justify-content-end">
                <Button
                    label="Cancel"
                    text
                    rounded
                    @click="clearShareInput"
                    class="mx-2"
                />
                <Button
                    label="Share"
                    rounded
                    @click="addCollaborators"
                    class="mx-2"
                />
            </div>
            <div
                v-else-if="pendingSharingActions"
                class="flex justify-content-end"
            >
                <Button
                    label="Cancel"
                    text
                    rounded
                    @click="updateCollaboratorsList"
                    class="mx-2"
                />
                <Button
                    label="Save"
                    rounded
                    @click="removeCollaborators"
                    class="mx-2"
                />
            </div>
            <div v-else class="flex justify-content-end">
                <Button
                    label="Done"
                    rounded
                    @click="closeDialog"
                    class="mx-2"
                />
            </div>
        </template>
    </Dialog>
</template>
<style scoped lang="scss">
::v-deep(.p-autocomplete-multiple-container) {
    width: 100% !important;
    box-shadow: none !important;
}
</style>
