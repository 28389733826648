import SharingService from '@/services/sharing.service'
import { useMutation } from '@tanstack/vue-query'

export const useUpdatePassword = () => {
    const { $sharingService } = useNuxtApp()
    const sharingService = $sharingService as SharingService
    return useMutation({
        mutationFn: sharingService.updatePassword,
    })
}
